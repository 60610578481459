import { navigate } from 'gatsby'
import React, { useContext, useEffect, useReducer } from 'react'
import useForm from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import Next from '../../../assets/images/Talleres/next.svg'
import {
  modifyCitaPrevia,
  setCitaPreviaStep,
  storeCitaPrevia,
  UserDataProps,
} from '../../../context/actions/citaPreviaActions'
import AppContext from '../../../context/context'
import { t } from '../../../../common/i18n'
import logic from '../../../logic'
import Size from '../../../utils/media-queries-setup'
import Button from '../../buttons/Button'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import SpinnerWithText from '../../spinnerWithText/SpinnerWithText'
import {
  CitaPreviaEnum,
  citaPreviaSteps,
  PatineteCitaPreviaEnum,
  patineteCitaSteps,
} from '../CitaPreviaSteps'
import {
  datosUsuarioFields,
  datosUsuarioPatineteFields,
  InDatosUsuarioFields,
  InDatosUsuarioFieldsPatinete,
} from '../DatosUsuario/fieldValidations'
import DatosUsuarioForm from './DatosUsuarioForm/DatosUsuarioForm'
import styles from './index.module.scss'
import { CitaPreviaPageProps } from '../../../pages/cita-previa'
import mainMarcas from '../../../../specific/constants/mainMarcas'
import { normalizeMatricula } from '../../../constants/matricula'

const DatosUsuarioSchema = yup
  .object()
  .shape(datosUsuarioFields, [['matricula', 'matricula_moto']])

const DatosUsuarioPatineteSchema = yup
  .object()
  .shape(datosUsuarioPatineteFields)

const initialOptionsMarcas = [
  {
    label: '',
    options: [
      { value: '', label: 'Seleccionar' },
      { value: 'otra', label: 'Otra Marca' },
    ],
  },
  {
    label: 'Marcas principales',
    options: mainMarcas,
  },
]
const initialOptionsModelos = [
  { value: '', label: 'Seleccionar' },
  { value: 'otra', label: 'Otro Modelo' },
]

const initialState = {
  marcas: initialOptionsMarcas,
  modelos: initialOptionsModelos as { value: string; label: string }[],
  loading: false as boolean,
  error: null as string | null,
  vehicles: null as string | null,
  showDropDowns: false as boolean,
}

type State = typeof initialState

function reducer(state: State, action) {
  switch (action.type) {
    case 'GET_MARCAS':
      return {
        ...state,
        loading: true,
      }
    case 'GET_MARCAS_OK':
      return {
        ...state,
        loading: false,
        marcas: action.payload.marcas,
      }
    case 'GET_MARCAS_FAILED':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case 'GET_MODELOS_OK':
      return {
        ...state,
        loading: false,
        modelos: action.payload.modelos,
      }
    case 'FIND_VEHICLES':
      return {
        ...state,
        loading: true,
      }
    case 'FIND_VEHICLES_OK':
      return {
        ...state,
        loading: false,
        vehicles: action.payload.vehicles,
        showDropDowns: action.payload.showDropDowns,
      }
    case 'FIND_VEHICLES_FAILED':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload.error,
      }
    case 'GET_MARCAS_MODELOS_OK':
      return {
        ...state,
        loading: false,
        marcas: action.payload.marcas,
        modelos: action.payload.modelos,
      }
    case 'SHOW_DROPDOWNS':
      return {
        ...state,
        showDropDowns: true,
      }
  }
}

export default function DatosUsuario({ path }: CitaPreviaPageProps) {
  const { userInfo, citaPreviaState, dispatch, revisionState } =
    useContext(AppContext)
  const [localState, localDispatch] = useReducer(reducer, initialState)
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    triggerValidation,
  } =
    citaPreviaState.selectedService.vehicle !==
    t('cita_previa.servicios.patinete')
      ? useForm<InDatosUsuarioFields>({
          mode: 'onSubmit',
          validationSchema: DatosUsuarioSchema,
        })
      : useForm<InDatosUsuarioFieldsPatinete>({
          mode: 'onSubmit',
          validationSchema: DatosUsuarioPatineteSchema,
        })

  const device = Size.useMedia({
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'DESKTOP',
  }) as 'MOBILE' | 'TABLET' | 'DESKTOP'

  useEffect(() => {
    if (
      citaPreviaState.selectedService.vehicle !==
      t('cita_previa.servicios.moto')
    ) {
      if (citaPreviaState.modificando) {
        getMarcasModelos()
      } else {
        getMarcas()
      }
    }
    if (!userInfo.loggedIn) {
      localDispatch({
        type: 'SHOW_DROPDOWNS',
      })
    } else {
      getVehicles()
    }
  }, [])

  useEffect(() => {
    if (citaPreviaState.errors) {
      citaPreviaState.errors.forEach((error) => {
        setError(error.field, error.type, t(error.message))
      })
    } else {
      clearError()
    }
  }, [citaPreviaState.errors])

  useEffect(() => {
    if (
      citaPreviaState.citaPreviaId &&
      citaPreviaState.step === 3 &&
      !citaPreviaState.modificando
    ) {
      dispatch(
        setCitaPreviaStep(citaPreviaSteps[CitaPreviaEnum.CONFIRMACION].step)
      )
      navigate(citaPreviaSteps[CitaPreviaEnum.CONFIRMACION].route)
    } else if (
      citaPreviaState.citaPreviaId &&
      citaPreviaState.selectedService.vehicle ===
        t('cita_previa.servicios.patinete') &&
      !citaPreviaState.modificando
    ) {
      dispatch(
        setCitaPreviaStep(
          patineteCitaSteps[PatineteCitaPreviaEnum.CONFIRMACION].step
        )
      )
      navigate(patineteCitaSteps[PatineteCitaPreviaEnum.CONFIRMACION].route)
    }
  }, [citaPreviaState.citaPreviaId, citaPreviaState.modificando])

  const getMarcas = () => {
    localDispatch({
      type: 'GET_MARCAS',
    })
    logic
      .retrieveVehicleMarcas()
      .then((res) => {
        const newMarcas = [
          ...initialOptionsMarcas,
          {
            label: 'Marcas',
            options: res.allMarcas,
          },
        ]
        localDispatch({
          type: 'GET_MARCAS_OK',
          payload: {
            marcas: newMarcas,
          },
        })
      })
      .catch((err) => {
        localDispatch({
          type: 'GET_MARCAS_FAILED',
          payload: {
            error: 'Ha habido un error',
          },
        })
      })
  }

  const getMarcasModelos = () => {
    localDispatch({
      type: 'GET_MARCAS',
    })
    Promise.all([
      logic.retrieveVehicleMarcas(),
      logic.retrieveVehicleModelos(citaPreviaState.userData.marca),
    ])
      .then((res) => {
        const marcas = [...initialOptionsMarcas, ...res[0].marcasOptions]
        const modelos = [...initialOptionsModelos, ...res[1].modelosOptions]
        localDispatch({
          type: 'GET_MARCAS_MODELOS_OK',
          payload: {
            marcas,
            modelos,
          },
        })
      })
      .catch((err) => {
        localDispatch({
          type: 'SET_ERROR',
          payload: {
            error: t('cita_previa.datos_usuario.error_modelos'),
          },
        })
      })
  }

  const getVehicles = () => {
    if (!userInfo.loggedIn) {
      if (
        citaPreviaState.selectedService.vehicle !==
        t('cita_previa.servicios.moto')
      ) {
        localDispatch({
          type: 'SHOW_DROPDOWNS',
        })
        return null
      }
      return null
    }
    const id = userInfo.idUser

    localDispatch({
      type: 'FIND_VEHICLES',
    })
    logic
      .findClientVehicles(id)
      .then((vehicles) => {
        const newVehicles = vehicles.map((item) => ({
          value: `${item.cod_matricula} - ${item.marca} - ${item.modelo}`,
          label: `${item.cod_matricula} - ${item.marca} - ${item.modelo}`,
        }))
        const isForOwnVehicle = foundVehicle(vehicles)
        localDispatch({
          type: 'FIND_VEHICLES_OK',
          payload: {
            vehicles: newVehicles,
            showDropDowns: isShowDropDowns(vehicles, isForOwnVehicle),
          },
        })
      })
      .catch((err) => {
        localDispatch({
          type: 'FIND_VEHICLES_FAILED',
          payload: { error: t('alta_vehiculo.error_buscar_vehiculo') },
        })
      })
  }

  const foundVehicle = (vehicles) => {
    if (citaPreviaState.modificando) {
      const filteredVehicles = vehicles.filter(
        (item) => item.cod_matricula === citaPreviaState.userData.matricula
      )
      if (filteredVehicles.length) {
        return true
      }
    }
    return false
  }

  const isShowDropDowns = (vehicles, isForOwnVehicle) => {
    if (!citaPreviaState.modificando) {
      if (!vehicles.length) {
        return true
      }
      return false
    }
    if (!isForOwnVehicle && vehicles.length) {
      return true
    } else if (isForOwnVehicle && vehicles.length) {
      return false
    } else if (!vehicles.length) {
      return true
    } else {
      return false
    }
  }

  const onSubmit = (data) => {
    let scooterBrand
    // retrieve the scooterBrand
    if (
      citaPreviaState.selectedService.vehicle ===
      t('cita_previa.servicios.patinete')
    ) {
      scooterBrand = citaPreviaState.scooter.selectedBrand.marca
      data.marca = scooterBrand
    }
    const userData = {
      email: data.email,
      name: data.name,
      phone: data.phone,
      origin: citaPreviaState.origin,
      device,
      comment: data.comment,
      marca: scooterBrand || '',
      matricula: '',
      modelo: '',
    } as UserDataProps

    if (data.hasOwnProperty('matriculaMarcaModelo')) {
      userData.marca = data.matriculaMarcaModelo.split('-')[1].trim()
      userData.matricula = data.matriculaMarcaModelo.split('-')[0].trim()
      userData.modelo = data.matriculaMarcaModelo.split('-')[2].trim()
    } else {
      userData.marca = data.marca
      userData.matricula =
        citaPreviaState.selectedService.vehicle ===
        t('cita_previa.servicios.moto')
          ? data.matricula_moto
          : data.matricula
      userData.modelo = data.modelo
    }

    userData.matricula = normalizeMatricula(userData.matricula)

    const { selectedDate, selectedService, selectedTaller, fromRevision } =
      citaPreviaState
    if (citaPreviaState.modificando) {
      dispatch(
        modifyCitaPrevia(
          userData,
          selectedDate,
          selectedService,
          selectedTaller,
          citaPreviaState.citaPreviaId
        )
      )
      return null
    }

    const dataRegisterLeadMatricula = {
      matricula: revisionState.matricula,
      aceite_escogido: null,
      dispositivo: device,
      id_site_usuario: userInfo.idUser,
      postCode: userInfo.postCode || revisionState.postCode,
    }

    dispatch(
      storeCitaPrevia(
        userData,
        selectedDate,
        selectedService,
        selectedTaller.codigo_taller,
        fromRevision ? dataRegisterLeadMatricula : null
      )
    )
  }

  const onChangeMarca = (event) => {
    logic
      .retrieveVehicleModelos(event.target.value)
      .then(({ modelosOptions }) => {
        const newModelos = [...initialOptionsModelos, ...modelosOptions]
        localDispatch({
          type: 'GET_MODELOS_OK',
          payload: {
            modelos: newModelos,
          },
        })
      })
      .catch((err) => {
        localDispatch({
          type: 'SET_ERROR',
          payload: {
            error: t('cita_previa.datos_usuario.error_modelos'),
          },
        })
      })
  }

  const onShowDropDowns = () => {
    localDispatch({
      type: 'SHOW_DROPDOWNS',
    })
  }

  const onContinue = () => {}

  const onGoBack = () => {
    if (
      citaPreviaState.selectedService.vehicle ===
      t('cita_previa.servicios.patinete')
    ) {
      dispatch(
        setCitaPreviaStep(
          patineteCitaSteps[PatineteCitaPreviaEnum.SELECCION_HORARIO].step
        )
      )
      navigate(
        patineteCitaSteps[PatineteCitaPreviaEnum.SELECCION_HORARIO].route
      )
    } else {
      dispatch(
        setCitaPreviaStep(
          citaPreviaSteps[CitaPreviaEnum.SELECCION_HORARIO].step
        )
      )
      navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_HORARIO].route)
    }
  }

  return (
    <LayoutSelector
      stepActive={citaPreviaState.step}
      customSteps={
        citaPreviaState.selectedService.vehicle ===
        t('cita_previa.servicios.patinete')
          ? patineteCitaSteps
          : citaPreviaSteps
      }
      title={t('cita_previa.datos_usuario.title')}
      subTitle={t('cita_previa.datos_usuario.subTitle')}
      mobileStep={citaPreviaState.step}
      totalSteps={citaPreviaSteps.length}
      showCopyrightFooter>
      <div className={styles.horarios_container}>
        <div className={styles.horarios_container_left}>
          {localState.loading && (
            <SpinnerWithText text={t('selector.cargando')} />
          )}
          <form
            className={localState.loading ? styles.hidden : undefined}
            onChange={(e) => {
              const target = e.target as HTMLInputElement
              // Revalidar cualquier campo cuando cambia su valor
              clearError(target.name as 'phone' | 'email')
              triggerValidation({
                name: target.name as 'phone' | 'email',
              })
            }}
            onSubmit={(e) => {
              // Validar todo el formulario antes de tratar de enviar
              triggerValidation()
              handleSubmit(onSubmit)(e)
            }}>
            <DatosUsuarioForm
              vehicles={localState.vehicles || []}
              onChangeMarca={onChangeMarca}
              marcasOptions={localState.marcas}
              vehicleType={citaPreviaState.selectedService.vehicle}
              modelosOptions={localState.modelos}
              errors={errors}
              formRef={register}
              userInfo={userInfo}
              globalError={citaPreviaState.error}
              onShowDropDowns={onShowDropDowns}
              showDropDowns={localState.showDropDowns}
            />
            <div className={styles.button_cont}>
              <Button buttonClass="next_button" onClick={onContinue}>
                <FormattedMessage id="selector-desktop.continuar" />
                <Next />
              </Button>
            </div>
            <div className={styles.cestaLastRowWrapper}>
              <CestaLastRow
                onContinuarClick={onContinue}
                onBackClick={onGoBack}
              />
            </div>
          </form>
        </div>
        <FunnelSideBar
          currentLocation="cita-previa"
          citaPreviaState={citaPreviaState}
        />
      </div>
    </LayoutSelector>
  )
}
