import React from 'react'
import { FieldErrors, FieldName } from 'react-hook-form/dist/types'
import Error from '../error/Error'
import FormError from '../FormError/FormError'
import InfoIcon from '../infoIcon/InfoIcon'
import styles from './index.module.scss'

interface WithFormError<T> {
  name: FieldName<T>
  errors: FieldErrors<T>
}

interface WithStringError {
  name: string
  errors: string
}

type withErrors<T> = WithFormError<T> | WithStringError

type InputCestaProps<T> = {
  label?: string | React.ReactFragment
  refe: React.LegacyRef<HTMLInputElement>
  type?: string
  defaultValue?: string
  inputSize?: string
  withInfoIcon?: boolean
  infoIconText?: string
  maxLength?: number
  errorBelow?: boolean
} & withErrors<T>

function InputCesta<T>({
  label,
  name,
  refe,
  errors,
  type,
  defaultValue,
  inputSize,
  withInfoIcon,
  infoIconText,
  maxLength,
  errorBelow = false,
}: InputCestaProps<T>) {
  return (
    <div className={styles.inputCesta_wrapper}>
      {label && (
        <label className={styles.label}>
          {label}
          {withInfoIcon ? <InfoIcon text={infoIconText} /> : null}
        </label>
      )}
      <div className={styles.inputWrapper}>
        <input
          defaultValue={defaultValue || ''}
          name={name as string}
          type={type}
          ref={refe}
          maxLength={maxLength}
          className={styles[inputSize] || ''}></input>
        {!errorBelow &&
          (typeof errors === 'string' ? (
            errors && <Error message={errors} />
          ) : (
            <FormError errors={errors} field={name as FieldName<T>} />
          ))}
      </div>
      {errorBelow && (
        <div className={styles.errorContainer}>
          {typeof errors === 'string' ? (
            errors && <Error message={errors} />
          ) : (
            <FormError errors={errors} field={name as FieldName<T>} />
          )}
        </div>
      )}
    </div>
  )
}

export default InputCesta
