import React, { useContext, useEffect, useReducer } from 'react'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import AppContext from '../../../context/context'
import {
  AreaPrivadaCitaPreviaEnum,
  areaPrivadaCitaSteps,
  CitaPreviaEnum,
  citaPreviaSteps,
  PatineteCitaPreviaEnum,
  patineteCitaSteps,
} from '../CitaPreviaSteps'
import { t } from '../../../../common/i18n'
import styles from './index.module.scss'
import Size from '../../../utils/media-queries-setup'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import Next from '../../../assets/images/Talleres/next.svg'
import HorariosContainer from '../../cesta/horarios/HorariosContainer'
import Button from '../../buttons/Button'
import { FormattedMessage } from 'react-intl'
import CestaLastRow from '../../cesta/cestaItem/cestaLastRow/CestaLastRow'
import {
  getInitialDates,
  getTimes,
  IDate,
  ITime,
} from '../../../api/citaPrevia'
import {
  setCitaPreviaStep,
  setSelectedDate,
  storeCitaPrevia,
} from '../../../context/actions/citaPreviaActions'
import { alertProp } from '../../../utils/swal'
import Swal from 'sweetalert2'
import { navigate } from 'gatsby'
import { CitaPreviaPageProps } from '../../../pages/cita-previa'

const initialState = {
  datesLoading: false as boolean,
  dates: null as IDate[] | null,
  selectedDate: null as IDate | null,

  timesLoading: false as boolean,
  times: null as ITime[] | null,
  selectedTime: null as ITime | null,

  editingSelectedTime: null as ITime | null,
  error: null as string | null,
}

interface SetDatesAction {
  type: 'SET_DATES'
}
interface SetDatesOkAction {
  type: 'SET_DATES_OK'
  dates: IDate[]
  selectedDate: IDate
  selectedTime: ITime
}
interface SetDatesFailedAction {
  type: 'SET_DATES_FAILED'
  error: string
}
interface SelectDateAction {
  type: 'SELECT_DATE'
  selectedDate: IDate
}
interface SelectDateOkAction {
  type: 'SELECT_DATE_OK'
  times: ITime[]
}
interface SelectDateFailedAction {
  type: 'SELECT_DATE_FAILED'
  error: string
}
interface SelectTtimeAction {
  type: 'SELECT_TIME'
  selectedTime: ITime
}
interface SetOriginalTimeAction {
  type: 'SET_ORIGINAL_TIME'
  editingSelectedTime: ITime
}
type IAction =
  | SetDatesAction
  | SetDatesOkAction
  | SetDatesFailedAction
  | SelectDateAction
  | SelectDateOkAction
  | SelectDateFailedAction
  | SelectTtimeAction
  | SetOriginalTimeAction

type IState = typeof initialState

function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case 'SET_DATES':
      return {
        ...state,
        datesLoading: true,
        timesLoading: true,
      }
    case 'SET_DATES_OK':
      return {
        ...state,
        datesLoading: false,
        timesLoading: false,
        dates: action.dates,
        selectedDate: action.selectedDate,
        selectedTime: action.selectedTime,
        error: null,
      }
    case 'SET_DATES_FAILED':
      return {
        ...state,
        datesLoading: false,
        timesLoading: false,
        error: action.error,
      }
    case 'SELECT_DATE':
      return {
        ...state,
        timesLoading: true,
        selectedDate: action.selectedDate,
      }
    case 'SELECT_DATE_OK':
      return {
        ...state,
        times: action.times,
        timesLoading: false,
        error: null,
      }
    case 'SELECT_DATE_FAILED':
      return {
        ...state,
        error: action.error,
      }
    case 'SELECT_TIME':
      return {
        ...state,
        selectedTime: action.selectedTime,
      }
    case 'SET_ORIGINAL_TIME':
      return {
        ...state,
        editingSelectedTime: action.editingSelectedTime,
      }
  }
  return state
}

const findIDateByITime = (fechas: IDate[], hora: ITime | null) => {
  if (!hora) {
    return null
  }

  const ymdDate = hora.date.split('-').reverse().join('-')
  return fechas.find((f) => f.originalDate === ymdDate)
}

export default function Horarios({
  path,
  nextStep,
  nextStepAreaPrivada,
  nextStepPatinete,
  location: {
    state: { area_privada },
  },
}: CitaPreviaPageProps) {
  const { citaPreviaState, dispatch } = useContext(AppContext)
  const [localState, localDispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    getDates()
  }, [])

  const getDates = () => {
    localDispatch({
      type: 'SET_DATES',
    })
    getInitialDates(
      citaPreviaState.selectedTaller.codigo_taller,
      citaPreviaState.modificando
        ? citaPreviaState.modificando.id_navision
        : null
    )
      .then(({ fechas }) => {
        const fechaSeleccionada =
          findIDateByITime(fechas, citaPreviaState.selectedDate) || fechas[0]
        localDispatch({
          type: 'SET_DATES_OK',
          dates: fechas,
          selectedDate: fechaSeleccionada,
          selectedTime: citaPreviaState.selectedDate,
        })
        onSelectDate(fechaSeleccionada)
      })
      .catch((err) => {
        localDispatch({
          type: 'SET_DATES_FAILED',
          error: t('cita_previa.horarios.error'),
        })
      })
  }

  const onContinue = () => {
    if (localState.selectedDate === null || localState.selectedTime === null) {
      Swal.fire(
        alertProp({
          type: 'error',
          text: t('cita_previa.horarios.required'),
          title: 'OOps...',
        })
      )
      return null
    }
    dispatch(setSelectedDate(localState.selectedTime))
    if (!area_privada && !path.includes('patinet')) {
      dispatch(setCitaPreviaStep(nextStep.step))
      navigate(nextStep.route)
    } else if (!area_privada && path.includes('patinet')) {
      dispatch(setCitaPreviaStep(nextStepPatinete.step))
      navigate(nextStepPatinete.route)
    } else {
      const { userData, selectedService, selectedTaller, fromRevision } =
        citaPreviaState
      const selectedDate = localState.selectedTime
      dispatch(
        storeCitaPrevia(
          userData,
          selectedDate,
          selectedService,
          selectedTaller.codigo_taller,
          fromRevision
        )
      )
      dispatch(setCitaPreviaStep(nextStepAreaPrivada.step))
      navigate(nextStepAreaPrivada.route, {
        state: { area_privada: true },
      })
    }
  }

  const onSelectDate = (data: IDate) => {
    localDispatch({
      type: 'SELECT_DATE',
      selectedDate: data,
    })
    getTimes(
      citaPreviaState.selectedTaller.codigo_taller,
      data.originalDate,
      citaPreviaState.modificando
        ? citaPreviaState.modificando.id_navision
        : null,
      citaPreviaState.selectedService.service
    )
      .then(({ horarios }) => {
        localDispatch({
          type: 'SELECT_DATE_OK',
          times: horarios,
        })
      })
      .catch((err) => {
        localDispatch({
          type: 'SELECT_DATE_FAILED',
          error: t('cita_previa.horarios.error'),
        })
      })
  }

  const onSelectTime = (time: ITime) => {
    localDispatch({
      type: 'SELECT_TIME',
      selectedTime: time,
    })
  }

  const onGoBack = () => {
    if (
      !area_privada &&
      citaPreviaState.selectedService.vehicle !==
        t('cita_previa.servicios.patinete')
    ) {
      dispatch(
        setCitaPreviaStep(
          citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO].step
        )
      )
      navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO].route)
    } else if (
      !area_privada &&
      citaPreviaState.selectedService.vehicle ===
        t('cita_previa.servicios.patinete')
    ) {
      dispatch(
        setCitaPreviaStep(
          patineteCitaSteps[PatineteCitaPreviaEnum.SELECCION_TALLER].step
        )
      )
      navigate(
        patineteCitaSteps[PatineteCitaPreviaEnum.SELECCION_TALLER].route,
        {
          state: { edit_scooter: true },
        }
      )
    } else {
      dispatch(
        setCitaPreviaStep(
          areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.OPTIONAL_COMMENT].step
        )
      )
      navigate(
        areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.OPTIONAL_COMMENT].route,
        { state: { area_privada: true } }
      )
    }
  }

  let customSteps = area_privada ? areaPrivadaCitaSteps : citaPreviaSteps
  if (
    citaPreviaState.selectedService.vehicle ===
    t('cita_previa.servicios.patinete')
  ) {
    customSteps = patineteCitaSteps
  }

  return (
    <LayoutSelector
      stepActive={citaPreviaState.step}
      customSteps={customSteps}
      title={t('cita_previa.horarios.title')}
      subTitle={t('cita_previa.horarios.subTitle')}
      mobileStep={citaPreviaState.step}
      totalSteps={
        area_privada ? areaPrivadaCitaSteps.length : citaPreviaSteps.length
      }
      showCopyrightFooter>
      <div className={styles.horarios_container}>
        <div className={styles.horarios_container_left}>
          {localState.error && <p>{localState.error}</p>}
          <HorariosContainer
            selectedCodigoTaller={citaPreviaState.selectedTaller.codigo_taller}
            selectedCodigoPostal={citaPreviaState.codigoPostalIntroducido}
            datesLoading={localState.datesLoading}
            dates={localState.dates}
            onSelectDate={onSelectDate}
            selectedDate={localState.selectedDate}
            timesLoading={localState.timesLoading}
            times={localState.times}
            onSelectTime={onSelectTime}
            selectedTime={localState.selectedTime}
            origen="cita_previa"
          />
          <Size.Matcher
            mobile={
              <div className={styles.button_cont}>
                <Button buttonClass="next_button" onClick={onContinue}>
                  <FormattedMessage id="selector-desktop.continuar" />
                  <Next />
                </Button>
              </div>
            }
            desktop={
              <CestaLastRow
                onContinuarClick={onContinue}
                onBackClick={onGoBack}></CestaLastRow>
            }
          />
        </div>
        <Size.Matcher
          mobile={null}
          desktop={
            <FunnelSideBar
              currentLocation="cita-previa"
              citaPreviaState={citaPreviaState}
              area_privada={area_privada}
            />
          }
        />
      </div>
    </LayoutSelector>
  )
}
